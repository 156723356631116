import React from "react"
import get from "lodash/get"
import map from "lodash/map"
import truncate from "lodash/truncate"
import kebabCase from "lodash/kebabCase"
import { v4 as uuid } from "uuid"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { FacebookShareButton } from "react-share"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import FbIcon from "../images/fb.svg"
import getNornalizeDate from '../helpers/getNornalizeDate'

const useStyles = makeStyles(theme => ({
  bgImage: {
    marginTop: 124,
  },
  blogList: {
    paddingBottom: 100,
  },
  pageTitle: {
    color: theme.palette.text.disabled,
    margin: "80px auto 0",
    textAlign: "center",
    fontWeight: "bold",
  },
  titleLink: {
    color: "initial",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  blogItemContainer: {
    color: "initial",
    display: "block",
    padding: "100px 20px",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    textDecoration: "none",
    transition: "background-color 200ms",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.025)",
    },
  },
  blogItemImage: {
    maxHeight: 600,
  },
  share: {
    color: theme.palette.text.disabled,
  },
  shareContainer: {
    width: "100%",
    marginRight: 15,
    "& svg": {
      marginLeft: 10,
    },
  },
}))

const BlogPage = props => {
  const classes = useStyles()

  const { allDatoCmsBlogPage, allDatoCmsHomePage } = props.data

  const getBgImage = get(allDatoCmsHomePage, "nodes[0].blogBgImage")
  const bgImage = getImage(getBgImage)

  const blogItems = map(allDatoCmsBlogPage.nodes, node => ({
    image: getImage(node.blogImage),
    title: node.blogTitle,
    teaser: node.blogTeaser,
    date: getNornalizeDate(node.blogDate),
    content: node.blogContent,
  }))

  const BlogItem = ({ blogItem }) => {
    const teaser = truncate(blogItem.teaser, {
      length: 200,
    })

    const title = truncate(blogItem.title, {
      length: 60,
    })

    const fburl = `${props.location.href}${kebabCase(blogItem.title)}`

    return (
      <div className={classes.blogItemContainer}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <Link to={`/blog/${kebabCase(blogItem.title)}`}>
              <GatsbyImage
                alt="Blog image"
                image={blogItem.image}
                className={classes.blogItemImage}
              />
            </Link>
          </Grid>
          <Grid item sm={12} md={6}>
            <Link
              to={`/blog/${kebabCase(blogItem.title)}`}
              className={classes.titleLink}
            >
              <Typography variant="h4" component="h2" gutterBottom>
                {title}
              </Typography>
            </Link>
            <Typography variant="body2" gutterBottom>
              {blogItem.date}
            </Typography>
            <Typography variant="body2" component="div">
              <article dangerouslySetInnerHTML={{ __html: teaser }} />
            </Typography>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.shareContainer}
          >
            <FacebookShareButton url={fburl}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" className={classes.share}>
                  Udostępnij na
                </Typography>
                <FbIcon />
              </Box>
            </FacebookShareButton>
          </Box>
        </Grid>
      </div>
    )
  }

  return (
    <Layout>
      <Seo title="Blog | Kompleksowa obsługa księgowa, podatkowa i prawna" description="Kompleksowa obsługa księgowa, podatkowa i prawna | Monika Sochacka +48 795 995 368 | Warszawa" />
      <GatsbyImage
        alt="Blog image"
        image={bgImage}
        className={classes.bgImage}
      />
      <Container maxWidth="xl">
        <Typography component="h1" variant="h1" className={classes.pageTitle}>
          Blog
        </Typography>
        <Box className={classes.blogList}>
          {map(blogItems, blogItem => (
            <BlogItem blogItem={blogItem} key={uuid()} />
          ))}
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allDatoCmsBlogPage(sort: {fields: blogDate, order: DESC}) {
      nodes {
        blogTitle
        blogTeaser
        blogDate
        blogContent
        blogImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    allDatoCmsHomePage {
      nodes {
        blogBgImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`

export default BlogPage
